import axios from 'axios';
import * as Sentry from '@sentry/react';
import { getuserApiServiceInstance } from './UserApiService';
import { router } from '@inertiajs/react';

class ApiService {
    constructor() {
        const baseUrl = '/api';

        this.unauthorizedApi = axios.create({
            headers: {
                'Content-Type': 'application/json'
            }
        });

        this.api = axios.create({
            baseURL: baseUrl,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Same as this.api but without specifying the content type
        this.fileApi = axios.create({
            baseURL: baseUrl,
        });

        // Automatically attach credentials and authorization headers to each request
        this.api.interceptors.request.use(async config => {
            const userApiService = await getuserApiServiceInstance();
            const token = await userApiService.getUserToken();
            config.headers.Authorization = `Bearer ${token}`;
            config.withCredentials = true;
            return config;
        }, error => {
            return Promise.reject(error);
        });

        this.fileApi.interceptors.request.use(async config => {
            const userApiService = await getuserApiServiceInstance();
            const token = await userApiService.getUserToken();
            config.headers.Authorization = `Bearer ${token}`;
            config.withCredentials = true;
            return config;
        }, error => {
            return Promise.reject(error);
        });
    }

    async unauthorizedPost(path, data) {
        return resolveApiRequest(this.unauthorizedApi.post(path, data).then(response => response.data));
    }

    async get(path) {
        return resolveApiRequest(this.api.get(path).then(response => response.data));
    }

    async post(path, data) {
        return resolveApiRequest(this.api.post(path, data).then(response => response.data));
    }

    async postFile(path, data) {
        return resolveApiRequest(this.fileApi.post(path, data).then(response => response.data));
    }

    async put(path, data) {
        return resolveApiRequest(this.api.put(path, data).then(response => response.data));
    }

    async delete(path) {
        return resolveApiRequest(this.api.delete(path).then(response => response.data));
    }
}

async function resolveApiRequest(promise) {
    try {
        const res = await promise;
        if(res?.redirect){
            router.visit(res.redirect);
        }
        return res;
    } catch (err) {
        // Capture and send the error to Sentry
        Sentry.captureException(err);
        throw new Error(err?.response?.data?.message ?? "An error occurred");
    }
}

let apiServiceInstance;

const getApiServiceInstance = () => {
    if (!apiServiceInstance) {
        apiServiceInstance = new ApiService();
    }
    return apiServiceInstance;
}

export { getApiServiceInstance };
