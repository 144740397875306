import { getApiServiceInstance } from "./ApiService";

class SessionsApiService {
    constructor() {
        this.apiService = getApiServiceInstance();
        this.baseUrl = '/sessions'
    }

    async generaterateMapTeamTagIdToSessions() {
        const sessionsUpcoming = await this.getSessions('upcoming');
        const sessionsPast = await this.getSessions('past');
        let teamIdToSessions = {};
        for (let session of sessionsUpcoming?.data) {
            const teamTagId = session?.team_tag?.id;
            if (teamTagId) {

                if (teamIdToSessions[teamTagId]) {
                    teamIdToSessions[teamTagId] += 1;
                } else {
                    teamIdToSessions[teamTagId] = 1;
                }
            }
        }

        for (let session of sessionsPast?.data) {
            const teamTagId = session?.team_tag?.id;
            if (teamTagId) {
                if (teamIdToSessions[teamTagId]) {
                    teamIdToSessions[teamTagId] += 1;
                } else {
                    teamIdToSessions[teamTagId] = 1;
                }
            }

        }

        return teamIdToSessions;

    }


    async updateSessionTaskOrder(sessionId, sessionTaskId, indexPosition) {
        const url = this.baseUrl + `/${sessionId}/reorder/${sessionTaskId}/${indexPosition}`
        return await this.apiService.post(url);

    }


    async getSessions(pageName) {
        const timeframe = pageName === "upcoming" ? "upcoming" : "past";
        const url = `${this.baseUrl}/${timeframe}`;
        return await this.apiService.get(url);

    }

    async getSessionDetails(sessionId) {
        return await this.apiService.get(`${this.baseUrl}/${sessionId}`);

    }

    async createSession(reqBody) {
        return await this.apiService.post(`${this.baseUrl}`, reqBody);

    }

    async updateSession(sessionId, reqBody) {
        return await this.apiService.post(`${this.baseUrl}/${sessionId}`, reqBody);

    }



    async removeSession(id) {
        return await this.apiService.delete(`${this.baseUrl}/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });

    }

    async duplicateSession(id, data) {
        return await this.apiService.post(`${this.baseUrl}/duplicate/${id}`, data);

    }

    async addTaskToSession(sessionId, taskId, duration) {
        return await this.apiService.post(`${this.baseUrl}/${sessionId}/assign-task/${taskId}`, {
            duration
        });

    }

    async setSessionTaskDuration(sessionId, pivotId, reqBody){
        return await this.apiService.post(`${this.baseUrl}/${sessionId}/update-task-duration/${pivotId}`,reqBody);
    }

    async removeTaskFromSession(sessionId, pivotId) {
        return await this.apiService.delete(`${this.baseUrl}/${sessionId}/unassign-task/${pivotId}`);
    }
}

let sessionsApiServiceInstance;

export function getSessionsApiServiceInstance() {
    if (!sessionsApiServiceInstance) {
        sessionsApiServiceInstance = new SessionsApiService();
    }

    return sessionsApiServiceInstance;
}
